.recentSearchesContainer {
  margin-bottom: 44px;
}

.recentSearchesTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 17px;
}

.removeRecentSearch {
  width: var(--medium-icon-size);
  height: var(--medium-icon-size);
}

.recentSearchesTitle {
  font-size: var(--font-size-3);
  line-height: 21px;
  font-weight: bold;
  text-align: left;
  color: var(--text-color);
  padding-top: 5px;
}

.clearSearches {
  &:hover {
    cursor: pointer;
    color: var(--cloud-grey);
  }

  span {
    color: var(--text-color);
    line-height: 16px;
    font-weight: bold;
    font-size: var(--font-size-1);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-loose);
  }
}

.recentSearchLink {
  text-decoration: none !important;
}

.recentSearchItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 51px;
  border-bottom: solid 1px var(--elephant-grey);

  &.isFirstSearchTerm {
    border-top: solid 1px var(--elephant-grey);
  }

  :global(html.discord) & {
    border-bottom: solid 1px rgb(255 255 255 / 20%);

    &.isFirstSearchTerm {
      border-top: solid 1px rgb(255 255 255 / 20%);
    }
  }

  .recentSearchText {
    font-size: var(--font-size-2);
    line-height: 19px;
    text-align: left;
    color: var(--text-color);
    margin-right: 15px;
  }
}
